/*
  Copyright 2022 Yotta Media Labs Ltd. All rights reserved.
*/

.App {
  text-align: center;
}

.AppTextFixedWidth {
  font-family: "Courier New", Courier, monospace;
  font-size: 8px;
  text-align: left;
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
